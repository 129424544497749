/* global $ */
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const $wrapper = $(this.element);
    const $contentElements = $wrapper.find('.tab-content');
    const $tabs = $wrapper.find('.tabs li');
    $wrapper.find('.tabs li a').on('click', (e) => {
      e.preventDefault();
      $tabs.removeClass('is-active');
      $contentElements.removeClass('is-active');
      const $link = $(e.currentTarget);
      $link.parent('li').addClass('is-active');
      $($link.attr('href')).addClass('is-active');
    });
  }
}
