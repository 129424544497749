/* global $ */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['backgroundElement', 'closeButton', 'contentElement'];

  connect() {
    const modal = this.element;
    const contentElement = this.contentElementTarget;

    // Close button and/or click on background closes modal
    $(this.closeButtonTarget).on('click', () => {
      modal.classList.remove('is-active');
    });
    $(this.backgroundElementTarget).on('click', () => {
      modal.classList.remove('is-active');
    });

    // Image modals
    $('body').on('click', 'a.image-modal', (e) => {
      e.preventDefault();
      contentElement.innerHTML = `<p class="image"><img src="${$(e.currentTarget).attr('href')}"</p>`;
      modal.classList.add('is-active');
    });

    // Remote (ajax) modals
    $('body').on('click', 'a.remote-modal', (e) => {
      e.preventDefault();
      modal.classList.add('is-active');
      contentElement.innerHTML = 'Loading ...';
      $(contentElement).load($(e.currentTarget).attr('href'));
    });
  }
}
