/* global $, alert */
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const url = this.data.get('url');
    const $form = $('#add-to-cart-form');
    const $formFields = $form.find('.form-fields');
    $form.on('change', '#size_id, #color_id, #related_products input', () => {
      $form.find('.button').addClass('is-loading');
      $formFields.load(url, $form.serialize(), (responseText, textStatus, jqXHR) => {
        if (jqXHR.status === 200) {
          // Do nothing
        } else {
          alert('Došlo je do greške');
        }
      });
    });
  }
}
