/* global $ */
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.togglePaymentMethodNote();
    const self = this;
    $('input[name="order[payment_method]"]').on('change', () => {
      self.togglePaymentMethodNote();
    });
  }

  togglePaymentMethodNote() {
    const buttonContentMapping = {
      1: 'Potvrdi narudžbu',
      2: 'Potvrdi narudžbu',
      3: 'Nastavi na plaćanje',
      4: 'Nastavi na plaćanje'
    };
    $('.note[data-payment-method]').hide();
    const paymentMethod = $('input[name="order[payment_method]"]:checked').val();
    $(`.note[data-payment-method=${paymentMethod}]`).show();
    $('#cart-overview form button[type=submit]').html(buttonContentMapping[paymentMethod] || 'Potvrdi narudžbu');
  }
}
