import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['closeButton'];

  connect() {
    const closeButton = this.closeButtonTarget;
    closeButton.addEventListener('click', () => {
      const msgEl = closeButton.parentNode;
      msgEl.parentNode.removeChild(msgEl);
    });
  }
}

