/* global $, alert */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['acceptButton', 'background', 'closeButton', 'doNotAcceptButton'];

  connect() {
    const $checkbox = $('#customer_terms_accepted, #order_terms_accepted');
    $checkbox.on('click', (e) => {
      if ($checkbox.is(':checked')) {
        e.preventDefault();
        this.element.classList.add('is-active');
      }
    });
    $checkbox.parent('label').find('a').on('click', (e) => {
      e.preventDefault();
      this.element.classList.add('is-active');
    });

    // Mark as checked when accept button is clicked
    $(this.acceptButtonTarget).on('click', (e) => {
      e.preventDefault();
      $checkbox.prop('checked', true);
      this.element.classList.remove('is-active');
    });
    // Uncheck when do not accept button is clicked
    $(this.doNotAcceptButtonTarget).on('click', (e) => {
      e.preventDefault();
      $checkbox.prop('checked', false);
      this.element.classList.remove('is-active');
    });

    // Close button and/or click on background closes modal
    $(this.backgroundTarget).on('click', () => {
      this.element.classList.remove('is-active');
    });
    $(this.closeButtonTarget).on('click', (e) => {
      e.preventDefault();
      this.element.classList.remove('is-active');
    });

    // Prevent form from submitting if terms are not accepted
    $checkbox.parents('form').on('submit', (e) => {
      if (!$checkbox.is(':checked')) {
        e.preventDefault();
        alert('Morate prihvatiti uvjete korištenja i izjavu o zaštiti podataka za nastavak!');
      }
    });
  }
}
