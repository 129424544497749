/* eslint class-methods-use-this: ["error", { "exceptMethods": ["connect"] }] */
import { Controller } from 'stimulus';
import { tns } from 'tiny-slider/src/tiny-slider.module';

export default class extends Controller {
  connect() {
    const OPTIONS = {
      container: '#home-slideshow',
      mode: 'gallery',
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 5000,
      controls: false,
      nav: false,
      speed: 2000
    };
    tns(OPTIONS);
  }
}

