/* global $ */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'cancelRelatedProductChange',
    'changeRelatedProductButton',
    'modal',
    'modalContentElement',
    'moreInfoButton',
    'relatedProduct',
    'relatedProductThumb',
    'relatedProducts',
    'selectRelatedProductButton',
    'selectedColorIdInput',
    'selectedIdInput',
    'selectedRelatedProduct',
    'selectedSizeIdInput'
  ];

  connect() {
    this.refreshOpacityOnThumbs();

    // Show thumbs if rel. prod. selected and we want to change it
    if (this.hasChangeRelatedProductButtonTarget) {
      $(this.changeRelatedProductButtonTarget).on('click', (e) => {
        e.preventDefault();
        this.relatedProductsTarget.classList.remove('is-hidden');
        e.currentTarget.classList.add('is-hidden');
        this.cancelRelatedProductChangeTarget.classList.remove('is-hidden');
        $(this.relatedProductTargets).addClass('in-preview-mode').filter('.is-selected').addClass('preview');
        $(this.relatedProductThumbTargets).filter('.is-selected').addClass('is-pre-selected');
      });
    }

    // Show selected rel. prod. on cancel link click
    if (this.hasCancelRelatedProductChangeTarget) {
      $(this.cancelRelatedProductChangeTarget).on('click', (e) => {
        e.preventDefault();
        this.relatedProductsTarget.classList.add('is-hidden');
        e.currentTarget.classList.add('is-hidden');
        this.changeRelatedProductButtonTarget.classList.remove('is-hidden');
        $(this.relatedProductTargets).removeClass('preview in-preview-mode');
      });
    }

    // Show related product details (inline) when clicked
    $(this.element).on('click', '[data-target="related-products.relatedProductThumb"] a', (e) => {
      e.preventDefault();
      const $link = $(e.currentTarget);
      const $relatedProductEl = $(this.relatedProductTargets).filter(`[data-id=${$link.data('id')}]`);
      $(this.relatedProductThumbTargets).removeClass('is-pre-selected');
      $link.parent('[data-target="related-products.relatedProductThumb"]').addClass('is-pre-selected');
      $(this.relatedProductTargets).removeClass('preview');
      $relatedProductEl.addClass('preview');
      this.refreshOpacityOnThumbs();
    });

    // Selected related product when clicked on select button
    $(this.selectRelatedProductButtonTargets).on('click', (e) => {
      e.preventDefault();
      const $button = $(e.currentTarget);
      const id = $button.data('id');
      const colorId = $(`select#related_product_${id}_color`).val();
      const sizeId = $(`select#related_product_${id}_size`).val();

      $(this.selectedColorIdInputTarget).val(colorId);
      $(this.selectedSizeIdInputTarget).val(sizeId);
      $(this.selectedIdInputTarget).val(id).trigger('change');
    });

    // // Reload whole add to cart section when color/size is changed if it is selected related product
    $(this.element).on('change', 'select', (e) => {
      const $select = $(e.currentTarget);
      const $relatedProduct = $select.parents('.related-product');
      if ($relatedProduct.hasClass('is-selected')) {
        if ($select.attr('name').includes('size')) {
          $(this.selectedSizeIdInputTarget).val($select.val()).trigger('change');
        } else {
          $(this.selectedColorIdInputTarget).val($select.val()).trigger('change');
        }
      }
    });

    // Open more info in modal
    $(this.moreInfoButtonTargets).on('click', (e) => {
      e.preventDefault();
      this.modalTarget.classList.add('is-active');
      this.modalContentElementTarget.innerHTML = 'Učitavanje u tijeku ...';
      $(this.modalContentElementTarget).load(e.currentTarget.href);
    });

    // Close button and/or click on background closes modal
    $(this.modalTarget).on('click', '[aria-label=close]', (e) => {
      e.preventDefault();
      this.modalTarget.classList.remove('is-active');
    });


    // $(modalConfirmButton).on('click', () => {
    //   $(selectedIdInput).val($(modalConfirmButton).data('id')).trigger('change');
    // });
  }

  // If we have selected rel. prod. others will have reduced opacity
  refreshOpacityOnThumbs() {
    const $selected = $(this.relatedProductThumbTargets).filter('.is-pre-selected, .is-selected');
    if ($selected.length > 0) {
      $(this.relatedProductThumbTargets).css('opacity', '0.5');
      $selected.css('opacity', '1');
    }
  }
}
