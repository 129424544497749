/* eslint no-console:0 */
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import '../stylesheets/application.scss';

Rails.start();
Turbolinks.start();

const application = Application.start();
const context = require.context('../controllers/site', true, /\.js$/);
application.load(definitionsFromContext(context));

