import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggleButton', 'filters', 'closeButton', 'footer', 'typeCheckbox', 'subTypeCheckbox'];

  connect() {
    this.toggleButtonTarget.addEventListener('click', () => {
      this.toggleButtonTarget.classList.add('is-hidden-mobile');
      this.filtersTarget.classList.remove('is-hidden-mobile');
      this.footerTarget.classList.add('has-text-centered');
    });
    this.closeButtonTarget.addEventListener('click', (e) => {
      e.preventDefault();
      this.toggleButtonTarget.classList.remove('is-hidden-mobile');
      this.filtersTarget.classList.add('is-hidden-mobile');
      this.footerTarget.classList.remove('has-text-centered');
    });
    this.observeTypeCheckboxes();
  }

  observeTypeCheckboxes() {
    if (this.typeCheckboxTargets.length > 0) {
      this.typeCheckboxTargets.forEach((el) => {
        const subTypeCheckboxes = this.subTypeCheckboxTargets.filter(subEl => subEl.dataset.type === el.dataset.type);
        el.addEventListener('change', () => {
          if (!el.checked) {
            subTypeCheckboxes.forEach((subEl) => {
              const checkbox = subEl;
              checkbox.checked = false;
            });
          }
        });
      });
      this.subTypeCheckboxTargets.forEach((subEl) => {
        subEl.addEventListener('change', () => {
          if (subEl.checked) {
            this.typeCheckboxTargets.filter(el => el.dataset.type === subEl.dataset.type)[0].checked = true;
          }
        });
      });
    }
  }
}
