import { Controller } from 'stimulus';
import Swiper from 'swiper/js/swiper';

export default class extends Controller {
  static targets = ['container', 'thumbsContainer'];

  connect() {
    new Swiper(this.containerTarget, { // eslint-disable-line no-new
      centeredSlides: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      preloadImages: false,
      pagination: {
        el: '.swiper-pagination'
      },
      thumbs: {
        swiper: {
          el: this.thumbsContainerTarget,
          freeMode: true,
          grabCursor: true,
          observer: true,
          observeParents: true,
          slidesPerView: 5,
          spaceBetween: 10,
          watchSlidesProgress: true,
          watchSlidesVisibility: true
        }
      }
    });
  }
}
