import { Controller } from 'stimulus';
import baguetteBox from 'baguettebox.js';

export default class extends Controller {
  static targets = ['list'];

  connect() {
    baguetteBox.run(`#${this.listTarget.id}`);
  }
}

